#digitalBabelLibrary .theme-block {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-image: url(../../../assets/img/projects/digital-babel-library/bg.png);
  background-size: cover;

  .content {
    width: 100%;
    color: #fff;
    text-align: left;
  }

  .t1 {
    font-size: 75px;
    margin-bottom: 30px;
    font-weight: 500;
  }
  .t2 {
    font-size: 40px;
  }
  .desc {
    margin-bottom: 80px;

    span {
      text-decoration: underline;
    }
  }

  .more button {
    padding: 0;
    width: 150px;
    height: 40px;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
      border: #fff solid 2.5px;
      border-radius: 16px;
      box-sizing: border-box;
    }
  }
}

#digitalBabelLibrary .theme-block-ext {
  margin-top: 20px;
  display: none;
}

#digitalBabelLibrary .block-title {
  font-size: 100px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;

  span {
    display: inline-block;
    position: relative;
    line-height: 1;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 155px;
      height: 18px;
      left: 50%;
      top: 100%;
      transform: translate(-50%, 0);
      background-image: url(../../../assets/img/projects/crypto-art-driver/title-div.svg);
      background-size: contain;
    }
  }
}

#digitalBabelLibrary .block-subtitle {
  font-size: 56px;
  text-align: center;
  margin-bottom: 50px;
}

#digitalBabelLibrary .dynamics {
  .imgs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    justify-content: center;
    column-gap: 14px;
    row-gap: 20px;

    img {
      width: 100%;
      max-width: 357px;
      border-radius: 8px;
      box-shadow: 0 4px 16px 0 rgba($color: #000000, $alpha: 0.1);
    }
  }
}

#digitalBabelLibrary .interpret,
#digitalBabelLibrary .about,
#digitalBabelLibrary .theme-block-ext {
  .top-img {
    margin-bottom: 50px;

    img {
      display: block;
      width: 100%;
    }
  }

  p {
    line-height: 2;
    font-weight: 300;

    &.high {
      font-weight: 400;
      text-decoration: underline;
    }
  }
}

#digitalBabelLibrary .about {
  .content {
    display: flex;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba($color: #000000, $alpha: 0.1);
    overflow: hidden;
    max-width: 1000px;
    margin: auto;
    
    .text {
      position: relative;
      padding: 50px 40px 50px 30px;
    }

    img {
      display: block;
      width: 40%;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
    }

    .more {
      position: absolute;
      bottom: 50px;
      right: 40px;

      img {
        width: 108px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
