/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 19, 2021 */



@font-face {
    font-family: 'montserratblack';
    src: url('montserrat-black-2-webfont.woff2') format('woff2'),
         url('montserrat-black-2-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}