.ez-menubtn {
  padding: 0;
  border: none;
  background: transparent;
  font-size: inherit;
  line-height: 1;
  height: 1em;

  svg {
    width: 1em;
    height: 1em;
  }
}