$headerheight: 100px;
$navheight: 40px;
$TX_TIME: 10s;
$gray: rgba($color: #000000, $alpha: 0.15);
$black: rgba($color: #000000, $alpha: 0.75);

#home .theme-block {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  
  .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    background-size: cover;
  }

  .container {
    width: 100%;
  }

  .t1 {
    font-size: 75px;
    margin-bottom: 24px;
    font-weight: 500;

    span {
      font-size: 30px;

      &::before {
        content: "/";
        margin: 0 12px;
      }
    }
  }
  .t2 {
    font-size: 40px;
    margin-bottom: 120px;

    span {
      font-size: 20px;

      &::before {
        content: "/";
        margin: 0 6px;
      }
    }
  }

  .more button {
    padding: 0;
    width: 150px;
    height: 40px;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
      border: #fff solid 2.5px;
      border-radius: 16px;
      box-sizing: border-box;
    }
  }
}

#home .theme-slide {
  .slick-prev {
    left: 24px;
  }
  .slick-next {
    right: 24px;
  }
  .slick-prev,
  .slick-next {
    z-index: 3;
    height: 40px;
    width: 40px;

    &::before {
      font-size: 40px;
    }
  }
}

#home .theme-navs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
  height: $navheight;

  .link {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 5;
    cursor: pointer;

    button {
      border: #bbb solid 1px;
      background-color: transparent;
      font-size: 16px;
      color: #bbb;
      padding: 6px 10px;
      transition: all ease 0.2s;
      cursor: pointer;
    }

    &:hover button {
      border-color: #777;
      color: #777;
    }
  }

  .nav-item {
    height: 3px;
    width: 20px;
    border-radius: 1.5px;
    background-color: $gray;
    overflow: hidden;

    &:not(.active) .bar {
      display: none;
    }

    .bar {
      height: 100%;
      background-color: $black;
    }
  }
}

#home .features {
  margin: 100px 0;

  .tabs {
    text-align: center;
    margin-bottom: 40px;
    font-size: 25px;
    color: #999;

    span {
      display: inline-block;
      margin: 0 70px;
      position: relative;
    }

    span.active {
      color: #C52B7B;

      &::after {
        position: absolute;
        top: calc(100% + 5px);
        left: 50%;
        transform: translate(-50%, 0);
        content: "";
        display: block;
        width: 75px;
        height: 6px;
        border-radius: 3px;
        background-image: linear-gradient(to right, #D2393B 0%, #BD22A5 80%, #8AD140 100%);
      }
    }
  }
  .feature {
    height: 490px;
    color: #fff;
    align-items: flex-end;
    padding: 60px 32px;
    display: none;
    background-size: cover;
    border-radius: 8px;

    .subtitle {
      font-size: 100px;
      margin-bottom: 15px;
      line-height: 1;
    }
    .desc {
      font-size: 18px;
    }
    &.active {
      display: flex;
    }
  }
}

$workheight: 440px;

#home .works {
  margin: 100px 0;

  .content {
    position: relative;

    .data {
      height: $workheight;
      display: flex;
      column-gap: 15px;
    }

    .work {
      width: 25%;
      border-radius: 8px;
      display: flex;
      align-items: flex-end;
      background-size: cover;
      transition: all ease 0.2s;
      overflow: hidden;

      &.opened {
        box-shadow: 0 2px 4px 0 rgba($color: #000000, $alpha: 0.5);

        .work-content {
          background-color: rgba($color: #fff, $alpha: 1);
          transform: translate(0, 0);
        }
      }
      
      &-img {
        display: none;
      }
      &-content {
        background-color: rgba($color: #fff, $alpha: 0.6);
        transform: translate(0, 100px);
        transition: all ease 0.2s;
      }

      &-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        padding: 10px;
      }
      &-desc {
        padding: 12px 10px 13px 10px;
        font-size: 14px;
        line-height: 25px;
        height: 100px;
      }
    }
  }
}

$researchheight: 190px;

#home .researchs {
  margin: 100px 0;

  .research {
    position: relative;
    height: $researchheight;
    margin-bottom: 20px;
    display: flex;
    box-shadow: 0 4px 16px 0 rgba($color: #000000, $alpha: 0.1);
    border-radius: 8px;
    overflow: hidden;

    &-img {
      width: 50%;

      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &-content {
      width: 50%;
      background-color: #fff;
      cursor: pointer;
      width: 50%;
      padding: 18px 30px;

      &:hover {
        .research-title {
          text-decoration: underline;
        }
      }
    }

    &-btn {
      a {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;

        img {
          height: 33px;
        }
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    &-desc {
      font-size: 13px;
      line-height: 20px;
      font-weight: 300;
      color: #666;
      margin-bottom: 40px;
    }

    &-opt {
      text-align: right;
      font-size: 12px;
      opacity: 0.5;
    }
  }
}


#home .team {
  margin: 100px 0;

  .data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 15px;

    .member {
      min-width: 0;
      box-shadow: 0 4px 16px 0 rgba($color: #000000, $alpha: 0.1);
      border-radius: 4px;
      overflow: hidden;

      &-img {

        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      &-info {
        padding: 18px 21px 40px;
      }
      &-name {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 4px;
      }
      &-title {
        font-size: 12px;
        opacity: 0.7;
        line-height: 18px;
        height: 18px;
        margin-bottom: 24px;
      }
      &-desc {
        font-size: 12px;
      }
    }
  }
}

#home .partners {
  margin: 100px 0;

  .title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }
  .subtitle {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    opacity: 0.7;
    margin-bottom: 70px;
  }

  .data {
    text-align: center;

    img {
      height: 110px;
      width: 110px;
      margin: 0 15px;
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    }
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
