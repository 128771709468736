#digitalBabelLibrary .theme-block {
  height: 226px;
  padding-top: 44px;

  .container {
    padding: 0 40px;
  }

  .content {
    text-align: center;
  }

  .t1 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .t2 {
    font-size: 13px;
    margin-bottom: 4px;
  }
  .more button {
    font-size: 12px;
    width: 80px;
    height: 20px;

    &::before {
      border-width: 1px;
    }
  }

  .desc {
    display: none;
  }
}

#digitalBabelLibrary .theme-block-ext {
  display: block;
}

#digitalBabelLibrary .block-title {
  font-size: 50px;
  margin-bottom: 10px;
}
#digitalBabelLibrary .block-title span::after {
  width: 75px;
  height: 9px;
}
#digitalBabelLibrary .block-subtitle {
  font-size: 23px;
  margin-bottom: 12px;
}

#digitalBabelLibrary .dynamics .dy {
  box-shadow: none;
  background-color: #fff;
  column-gap: 12px;
}
#digitalBabelLibrary .dynamics .dy {
  padding: 13px 11px;
}
#digitalBabelLibrary .dynamics .dy .img {
  order: 2;
  width: 95px;
  border-radius: 4px;
  overflow: hidden;
}
#digitalBabelLibrary .dynamics .dy .info {
  padding: 0;
}
#digitalBabelLibrary .dynamics .dy .info .desc {
  display: none;
}
#digitalBabelLibrary .dynamics .dy .info .title {
  font-size: 14px;
}
#digitalBabelLibrary .dynamics .dy .info .data {
  font-size: 12px;
}

#digitalBabelLibrary .interpret .top-img, #digitalBabelLibrary .about .top-img, #digitalBabelLibrary .issued .top-img {
  margin-bottom: 12px;
}

#digitalBabelLibrary .about .content {
  display: block;
  box-shadow: none;
  border-radius: 0;
}
#digitalBabelLibrary .about .content img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
}
#digitalBabelLibrary .about .content .text {
  padding: 0;
}

#digitalBabelLibrary .about .content .more {
  position: static;
  float: right;
}

#digitalBabelLibrary .issue-slide {
  .issue {
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      display: block;
    }
  }

  .slick-dots {
    bottom: -20px;
  }
  .slick-dots li button::before {
    opacity: 1;
    color: #bbb;
  }
  .slick-dots li.slick-active {
    width: 24px;

    button {
      width: 24px;
    }
    button::before {
      content: "";
      width: 24px;
      background-image: url(../../../assets/img/projects/crypto-art-driver/slide-dot.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}
