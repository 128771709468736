#cryptoArtDriver .theme-block {
  height: 226px;
  padding-top: 44px;

  .container {
    padding: 0 40px;
  }

  .t1 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .t2 {
    font-size: 13px;
    margin-bottom: 4px;
  }
  .more button {
    font-size: 12px;
    width: 80px;
    height: 20px;

    &::before {
      border-width: 1px;
    }
  }
}

#cryptoArtDriver .block-title {
  font-size: 50px;
  margin-bottom: 10px;
}
#cryptoArtDriver .block-title span::after {
  width: 75px;
  height: 9px;
}
#cryptoArtDriver .block-subtitle {
  font-size: 23px;
  margin-bottom: 12px;
}

#cryptoArtDriver .dynamics .dy {
  box-shadow: none;
  background-color: #fff;
  column-gap: 12px;
}
#cryptoArtDriver .dynamics .dy {
  padding: 13px 11px;
}
#cryptoArtDriver .dynamics .dy .img {
  order: 2;
  width: 95px;
  border-radius: 4px;
  overflow: hidden;
}
#cryptoArtDriver .dynamics .dy .info {
  padding: 0;
}
#cryptoArtDriver .dynamics .dy .info .desc {
  display: none;
}
#cryptoArtDriver .dynamics .dy .info .title {
  font-size: 14px;
}
#cryptoArtDriver .dynamics .dy .info .data {
  font-size: 12px;
}

#cryptoArtDriver .interpret .top-img, #cryptoArtDriver .about .top-img, #cryptoArtDriver .issued .top-img {
  margin-bottom: 12px;
}

#cryptoArtDriver .issue-slide,
#cryptoArtDriver .issue,
#cryptoArtDriver .issue .bg {
  height: 150px;
}
#cryptoArtDriver .issue .bg::after {
  height: 45px;
}
#cryptoArtDriver .issue .img::before {
  width: 140%;
}
#cryptoArtDriver .issue .info {
  left: 20px;
  bottom: 10px;

  .t1 {
    font-size: 26px;
  }
  .t2 {
    font-size: 12px;
  }
}
#cryptoArtDriver .issue-slide .slick-dots {
  transform: scale(0.7);
  bottom: 0px;
}

#cryptoArtDriver .compound .members {
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(3, 90px);
  justify-content: space-between;
  row-gap: 10px;
}
#cryptoArtDriver .compound .members img {
  height: 90px;
  width: 90px;
}

#cryptoArtDriver .about .img img {
  height: 100px;
  width: 100px;
}
#cryptoArtDriver .about .text {
  font-size: 14px;
}
