#cryptoArtDriver .marquee-container {
  span {
    font-size: 42px;
    font-weight: 600;
    color: #6f6f6f;
    margin: 0 24px;
    text-transform: uppercase;
  }

  .overlay {
    display: none;
  }
}

#cryptoArtDriver .theme-block {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-image: url(../../../assets/img/projects/crypto-art-driver/bg.png);
  background-size: cover;

  .content {
    width: 100%;
    color: #fff;
    text-align: center;
  }

  small {
    font-size: 50%;
  }

  .t1 {
    font-size: 75px;
    margin-top: -200px;
    margin-bottom: 30px;
    font-weight: 500;
  }
  .t2 {
      small {
    font-size: 60%;
  }
    font-size: 40px;
    margin-bottom: 100px;
  }

  .more button {
    padding: 0;
    width: 150px;
    height: 40px;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
      border: #fff solid 2.5px;
      border-radius: 16px;
      box-sizing: border-box;
    }
  }
}

#cryptoArtDriver .block-title {
  font-size: 100px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;

  span {
    display: inline-block;
    position: relative;
    line-height: 1;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 155px;
      height: 18px;
      left: 50%;
      top: 100%;
      transform: translate(-50%, 0);
      background-image: url(../../../assets/img/projects/crypto-art-driver/title-div.svg);
      background-size: contain;
    }
  }
}

#cryptoArtDriver .block-subtitle {
  font-size: 56px;
  text-align: center;
  margin-bottom: 50px;
}

#cryptoArtDriver .dynamics {
  .dy.empty {
    text-align: center;
    display: block;
    font-size: 20px;
    padding: 20px 0;
    color: #999;
  }

  .dy {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 16px rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: none;
    a {
      text-decoration: none;
      color: black;
    }

    &:hover {
      .title {
        text-decoration: underline;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .img {
      flex-grow: 0;
      flex-shrink: 0;
      width: 225px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .info {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 18px 36px;
      text-decoration: none;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        text-decoration: none;
        color: rgb(34, 34, 34);
      }
      .desc {
        color: rgb(34, 34, 34);;
        font-size: 13px;
        min-height: 82px;
        margin-bottom: 12px;
        text-decoration: none;
      }
      .data {
        font-size: 14px;
        color: #AAAAAA;
        display: flex;
        justify-content: space-between;

        span {
          line-height: 20px;

          &>* {
            vertical-align: middle;
          }

          img {
            height: 18px;
            width: 18px;
            margin-left: 20px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}

#cryptoArtDriver .interpret,
#cryptoArtDriver .about,
#cryptoArtDriver .issued {
  .top-img {
    margin-bottom: 50px;

    img {
      display: block;
      width: 100%;
    }
  }

  p {
    line-height: 2;
    font-weight: 300;

    &.high {
      font-weight: 400;
      text-decoration: underline;
    }
  }
}

#cryptoArtDriver .issue {
  width: 100%;
  position: relative;
  height: 425px;

  .bg {
    position: relative;
    background-color: #9A9A9A;
    height: 425px;

    &::after {
      content: "";
      position: absolute;
      display: block;
      height: 130px;
      width: 100%;
      bottom: 0;
      left: 0;
      border-radius: 10px 10px 0 0;
      background-color: #525252;
    }
  }
  .img {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    height: 100%;

    &::before {
      position: absolute;
      content: "";
      display: block;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      height: 100%;
      width: 550px;
      border-radius: 10px;
      background-color: rgba($color: #000000, $alpha: 0.3);
    }

    img {
      position: relative;
      height: 100%;
      z-index: 3;
      object-fit: cover;
    }
  }
  .info {
    position: absolute;
    bottom: 35px;
    left: 56px;
    color: #fff;

    .t1 {
      font-size: 70px;
      font-weight: 500;
      line-height: 1;
    }
    .t2 {
      font-size: 30px;
      font-weight: 500;
    }
  }
}

#cryptoArtDriver .issue-slide {
  overflow: hidden;
  border-radius: 10px;
  height: 425px;

  .slick-dots {
    bottom: 35px;
  }
  .slick-dots li button::before {
    opacity: 1;
    color: #ddd;
  }
  .slick-dots li.slick-active {
    width: 24px;

    button {
      width: 24px;
    }
    button::before {
      content: "";
      width: 24px;
      background-image: url(../../../assets/img/projects/crypto-art-driver/slide-dot.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}

#cryptoArtDriver .compound {
  
  .members {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    img {
      display: block;
      height: 120px;
      width: 120px;
      border-radius: 60px;
      object-fit: cover;
      box-shadow: 0 6px 21px 0 rgba($color: #000000, $alpha: 0.1);
    }
  }

  .dy {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 16px rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .img {
      flex-grow: 0;
      flex-shrink: 0;
      width: 190px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .info {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 25px 45px;

      .title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 12px;
      }
      .desc {
        font-size: 13px;
        color: #666666;
      }
    }
  }

  .dy-mobile {
    box-shadow: 0 4px 16px rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 8px;
    padding: 15px;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 9px;

      img {
        height: 55px;
        width: 55px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px;
      }
      span {
        font-size: 18px;
        font-weight: 500;
      }
    }
    .desc {
      color: #666666;
      line-height: 26px;
    }
  }
}

#cryptoArtDriver .entry {
  text-align: center;

  .img img {
    height: 185px;
    width: 185px;
    object-fit: contain;
  }
  .text {
    font-size: 25px;
    font-weight: 300;
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
