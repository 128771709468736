/* @font-face kit by Fonts2u (https://fonts2u.com) */ 
@font-face {
  font-family:"OswaldStencil-Bold";
  src:url("Oswald-Stencil.eot?") format("eot"),
  url("Oswald-Stencil.woff") format("woff"),
  url("Oswald-Stencil.ttf") format("truetype"),
  url("Oswald-Stencil.svg#OswaldStencil-Bold") format("svg");
  font-weight:normal;
  font-style:normal;
}
