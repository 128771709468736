#home .theme-block {
  height: 226px;
  padding-top: 44px;

  .container {
    padding: 0 40px;
  }

  .t1 {
    font-size: 28px;
    margin-bottom: 4px;

    span {
      display: none;
    }
  }
  .t2 {
    font-size: 13px;
    margin-bottom: 30px;

    span {
      display: none;
    }
  }
  .more button {
    font-size: 12px;
    width: 80px;
    height: 20px;

    &::before {
      border-width: 1px;
    }
  }
}

#home .features {
  margin: 75px 0;

  .tabs {
    font-size: 12px;
    margin-bottom: 12px;
  }
  .tabs span {
    margin: 0 20px;
  }
  .tabs span.active {
    font-size: 14px;
  }
  .tabs span.active::after {
    top: calc(100% + 2px);
    width: 25px;
    height: 2px;
  }
  .feature {
    height: 172px;
    padding: 10px 18px;

    .subtitle {
      font-size: 26px;
    }
    .desc {
      font-size: 12px;
    }
  }
}

#home .works {
  margin: 75px 0;
}
#home .works .content .data {
  display: block;
  height: auto;
}
#home .works .content .work {
  background-color: #fff;
  width: auto;
  padding: 12px;
  align-items: flex-start;
  background-image: none !important;
  margin-bottom: 8px;
  column-gap: 12px;

  &.opened {
    box-shadow: none;
  }
}
#home .works .content .work-img {
  display: block;
  border-radius: 4px;
  overflow: hidden;
  height: 85px;
  width: 85px;
  flex-grow: 0;
  flex-shrink: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
#home .works .content .work-content {
  transform: translate(0, 0);
}
#home .works .content .work-title {
  padding: 0;
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 1;
}
#home .works .content .work-desc {
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  color: #666666;
  height: auto;
}


#home .researchs {
  margin: 75px 0;
}
#home .researchs .research {
  height: auto;
}
#home .researchs .research-img {
  width: 31%;
  flex-grow: 1;
  flex-shrink: 1;
}
#home .researchs .research-content {
  width: 69%;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 12px;
}
#home .researchs .research-desc {
  margin-bottom: 0;
}
#home .researchs .research-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 31%;
  padding: 12px 18px;
}
#home .researchs .research-btn a {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 3px;
  border: #fff solid 1px;
  border-radius: 4px;
}
#home .researchs .research-btn a img {
  display: none;
}
#home .researchs .research-btn a::after {
  content: "了解更多";
  font-size: 12px;
  color: #fff;
}

#home .team .data {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 11px;
  row-gap: 11px;
}
#home .team .data .member-img img {
  height: 65px;
  width: 65px;
  margin: auto;
}
#home .team .data .member-img {
  text-align: center;
  height: auto;
  margin-bottom: 10px;
}
#home .team .data .member-info {
  padding: 0;
}
#home .team .data .member {
  padding: 12px 15px 6px 15px;
  box-shadow: none;
  background-color: #fff;
}
#home .team .data .member-name {
  text-align: center;
  margin-bottom: 2px;
}
#home .team .data .member-title {
  text-align: center;
  margin-bottom: 2px;
}
#home .team .data .member-desc {
  color: #666666;
  line-height: 20px;
  margin-bottom: 5px;
}

#home .team .data .member-alt-desc {
  color: #666666;
  font-size: x-small;
  line-height: 20px;
}

#home .partners .data img {
  height: 55px;
  width: 55px;
}
