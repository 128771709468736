@import url(./assets/font/montserrat-black/stylesheet.css);
@import url(./assets/font/dinpro-regular/stylesheet.css);
@import url(./assets/font/oswald-stencil/stylesheet.css);

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-montserrat-black {
  font-family: "montserratblack","PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}
.font-dinpro-regular {
  font-family: "DINProRegular","PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

.font-oswald-stencil {
    font-family: "OswaldStencil-Bold","PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

html,
body {
    height: 100%;
    position: relative;
    font-size: 14px;
    color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.root {
  height: 100%;
}

.container {
  max-width: 1240px;
  padding: 0 20px;
  margin: auto;

  &.sm {
    max-width: 640px;
  }
}

.single-page {
  padding-top: 100px;
  min-height: calc(100vh - 40px);
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
