header {
  padding: 10px 0;
  text-align: center;

  .logo {
    height: 24px;
    display: block;
    margin: auto;
  }

  .ez-menubtn {
    font-size: 24px;
    position: absolute;
    right: 12px;
    top: 10px;
  }

  .mobile-menu {
    overflow: hidden;
    height: 0;
    transition: all ease 0.3s;

    a {
      padding: 20px;
      font-size: 24px;
      display: block;
    }
  }

  &.opened {
    background-color: #fff;
    color: #000;

    &.top a {
      color: #000;
    }

    .mobile-menu {
      height: calc(100vh - 60px);
    }
  }
}

footer .content {
  display: block;
  text-align: center;
}
footer .content .row1 {
  width: auto;
}
footer .content .row2 {
  padding: 0;
  border-left: none;
}
footer .content .row1 .title {
  margin-bottom: 20px;
}
footer .content .row1 .link {
  margin-bottom: 10px;
}
