$amargin: 36px;
$logoheight: 40px;

header {
  position: fixed;
  width: 100vw;
  padding: 16px 0;
  z-index: 10;
  color: #000;
  background-color: #fff;
  transition: all ease 0.2s;

  a {
    color: rgba(30, 30, 30, 0.75);
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  &.top {
    background-color: transparent;
    color: #fff;
    box-shadow: none;

    a {
      color: #fff;
    }
  }

  .container {
    display: flex;
    align-items: center;

    .left {
      flex-grow: 1;
      flex-shrink: 1;
      width: 50%;
    }
    .right {
      flex-grow: 1;
      flex-shrink: 1;
      text-align: right;
      width: 50%;

      a {
        margin-left: $amargin;
      }
    }
  }

  .logo {
    height: $logoheight;
  }
}

footer {
  background-color: #333;
  color: rgba($color: #fff, $alpha: 0.8);

  .content {
    padding-top: 50px;
    display: flex;

    .row1 {
      width: 240px;

      &:nth-child(2) {
        flex-grow: 1;
        flex-shrink: 1;
      }

      .title {
        font-size: 20px;
        color: #fff;
        margin-bottom: 40px;
      }

      .link {
        margin-bottom: 18px;
        line-height: 1;

        a {
          color: rgba($color: #fff, $alpha: 0.5);
          text-decoration: none;

          &:hover {
            color: #fff;
          }
        }
      }
    }

    .row2 {
      text-align: center;
      padding-left: 80px;
      border-left: rgba($color: #979797, $alpha: 0.3) solid 1px;

      .title {
        font-size: 20px;
        color: #fff;
        margin-bottom: 15px;
      }

      .wechat {
        margin-top: 15px;
        line-height: 1;
        color: rgba($color: #fff, $alpha: 0.5);
      }

      img {
        width: 130px;
      }
    }

    .row1,
    .row2 {
      padding-top: 10px;
      padding-bottom: 40px;
    }
  }

  .email {
    font-size: 12px;
    text-align: center;
    margin-bottom: 7px;
    margin-top: 24px;

    a {
      color: rgba($color: #fff, $alpha: 0.8);
      text-decoration: none;

      &:hover {
        color: #fff;
      }
    }
  }
  .right {
    font-size: 12px;
    text-align: center;
    padding-bottom: 70px;
  }
}

.header-project-menu {
  a {
    text-decoration: none;
    color: inherit;
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
